/* DiscountCode List, Vue Component */
<template>
  <v-card id="discountcode-list">
    <v-card-title>Discount Codes</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'discountcode-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Discount Code</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>



        <v-select
          v-model="filter.Enabled"
          :items="filterItems.Enabled"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Enabled"
          class="mr-4"
        ></v-select>



        <!-- <v-select
          v-model="filter.SingleUsePerMember"
          :items="filterItems.SingleUsePerMember"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Single User Per Member"
          class="mr-4"
        ></v-select>



        <UserSelect class="mr-4" v-model="filter.UserId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" /> -->


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Code`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'discountcode-view', params: { id: item.Id } }">
      {{ item.Code }}
    </router-link>

    </template>

		<template #[`item.Enabled`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Enabled).variant">{{ booleanIcons(item.Enabled).icon }}</v-icon>
    </template>

		<template #[`item.OnlyProducts`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.OnlyProducts).variant">{{ booleanIcons(item.OnlyProducts).icon }}</v-icon>
    </template>

		<template #[`item.AbsoluteDiscount`]="{item}">
      {{ Display.Money(item.AbsoluteDiscount) }}
    </template>

		<template #[`item.SingleUse`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.SingleUse).variant">{{ booleanIcons(item.SingleUse).icon }}</v-icon>
    </template>

		<template #[`item.SingleUsePerMember`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.SingleUsePerMember).variant">{{ booleanIcons(item.SingleUsePerMember).icon }}</v-icon>
    </template>

		<template #[`item.ValidFrom`]="{item}">
      {{ Display.DateTime(item.ValidFrom) }}
    </template>

		<template #[`item.ValidTo`]="{item}">
      {{ Display.DateTime(item.ValidTo) }}
    </template>

    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import UserSelect from '@/views/user/UserSelect'

export default {
  components: {
    ActionsDropdown,
    UserSelect
  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Code', value: 'Code' },
		{ text: 'Enabled', value: 'Enabled' },
		{ text: 'Only Prod', value: 'OnlyProducts' },
		{ text: 'Absolute Discount', value: 'AbsoluteDiscount' },
		{ text: 'Percentage Discount', value: 'PercentageDiscount' },
		// { text: 'Single Use', value: 'SingleUse' },
		// { text: 'Single User Per Member', value: 'SingleUsePerMember' },
		{ text: 'Valid From', value: 'ValidFrom' },
		{ text: 'Valid To', value: 'ValidTo' },
		// { text: 'Products', value: 'ProductIds' },
		// { text: 'Order Types', value: 'OrderTypes' }
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['Created'],
    sortDesc: [false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.DiscountCodes || []);
  const totalItems = computed(() => store.state.app.DiscountCodesTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({
    Enabled: [{"text":"Enabled","value":true},{"text":"Disabled","value":false}],
		SingleUsePerMember: [{"text":"Single Use/Member","value":true},{"text":"Multiple Use/Member","value":false}]
  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchDiscountCodes', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
